import React from 'react'

function Init() {
    return (
        <div>
            <h1>PROYECTOS REALIZADOS</h1>
        </div>
    )
}

export default Init